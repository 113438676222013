import React, { Component, useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Nav, Form, Image, Button, Navbar, Dropdown, Container, ListGroup, InputGroup, NavDropdown, Modal, Tab, Tabs } from 'react-bootstrap';
import { MultiSelect } from "react-multi-select-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus, faCirclePlus, faFileArrowUp, faXmark } from '@fortawesome/free-solid-svg-icons';
//import SunEditor, { buttonList, height } from 'suneditor-react';
//import 'suneditor/dist/css/suneditor.min.css';
import { UseAuthContext } from '../context/AuthContext';

import { useToken } from './tokenContext';
import { variables, editorButtons } from '../Variables';

import { EmailLayoutModal } from './EmailLayout';
import { EamilTexteModal } from "./EmailTexte"
import { DeleteConfirmationModal } from './DeleteConfirmationModal';


const targetOptions = [
    { label: "Vendor", value: "1" },
    { label: "Lessor", value: "2" },
    { label: "Buyer", value: "3" },
    { label: "Tenant", value: "4" },
]

export const Trigger = (props) => {
    const token = useToken();
    const [triggerNameBuilder, setTriggerNameBuilder] = useState({ "default": "Trigger:" });
    const [finalTriggerName, setFinalTriggerName] = useState("Trigger:");
    const [whiseOfficeDetail, setWhiseOfficeDetail] = useState({});
    const [localOfficeDetail, setLocalOfficeDetail] = useState({});
    const [clientTokken, setClientTokken] = useState("");
    const [clientDetail, setClientDetail] = useState({});
    const [whiseOfficesList, setWhiseOfficesList] = useState({});
    const [data, setData] = useState([]);
    const [datafr, setDatafr] = useState([]);
    const [datanl, setDatanl] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectedTarget, setSelectedTarget] = useState([]);
    const [selectedLaytOutId, setSelectedLaytOutId] = useState(0);
    const [selectedLaytName, setSelectedLaytName] = useState("");
    const [officeLayout, setOfficeLayout] = useState([]);
    const [showLayoutModal, setShowLayoutModal] = useState(false);
    const [layoutModalTitle, setLayoutModalTitle] = useState("");
    const [layoutModalType, setLayoutModalType] = useState("");
    const [officeId, setOfficeId] = useState(0);
    const [clientId, setClientId] = useState(0);
    const [selectedTab, setSelectedTab] = useState("english");
    const [emailTexteEnglish, setEmailTexteEnglish] = useState("");
    const [emailTexteFrench, setEmailTexteFrench] = useState("");
    const [emailTexteDutch, setEmailTexteDutch] = useState("");
    const [durationValue, setDurationValue] = useState("");
    const [triggerDetail, setTriggerDetail] = useState({});
    const [suveryLinkForEmail, setSuveryLinkForEmail] = useState(["https://survey.realadvice.be/", "", "/?"]);
    const [contactPreference, setContactPreference] = useState("all");
    const [selectedProperty, setSelectedProperty] = useState([]);
    const [isCustomUnsubscription, setIsCustomUnsubscription] = useState(false);
    /*   Start New code from Abdul Saboor */

    const [showTexteModal, setShowTexteModal] = useState(false)
    const [texteTemplateData, setTexteTemplateData] = useState([])
    const [texteModalTitle, setTexteModalTitle] = useState("")
    const [texteModalType, setTexteModalType] = useState("")
    const [selectedTexteId, setSelectedTexteId] = useState(0);
    const [selectedTexteName, setSelectedTexteName] = useState("");
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [deleteModalTitle, setDeleteModalTitle] = useState("");
    const [deleteModalType, setDeleteModalType] = useState("");
    const [recentlySavedLayout, setRecentlySavedLayout] = useState({});
    const [recentlySavedTexteTemplate, setRecentlySavedTexteTemplate] = useState({});
    const [layoutsListWithoutCurrentLayout, setLayoutsListWithoutCurrentLayout] = useState([]);

    // new states for DeleteConfirmationModal Abdul Saboor
    const [texteListByWithoutCurrentTexte, setTexteListByWithoutCurrentTexte] = useState([]);
    const [showModalTexteDelete, setShowModalTexteDelete] = useState(false);
    const [deleteModalTexteTitle, setDeleteModalTexteTitle] = useState("");
    const [deleteModalTexteType, setDeleteModalTexteType] = useState("");
    const [includeUserList, setIncludeUserList] = useState([]);
    const [excuseUserList, setExcuseUserList] = useState([]);
    const [fileName, setFileName] = useState('');
    const [userRows, setUserRows] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userListDropDown, setUserListDropDown] = useState([]);
    const [userSelectDropDownName, setUserSelectDropDownName] = useState("");
    const [showAllDetailsUser, setShowAllDetailsUser] = useState(false);
    const [showMappingDoc, setShowMappingDoc] = useState(false);
    const [showUserField, setShowUserField] = useState(false);
    const [showSecondUserField, setShowSecondUserField] = useState(false);
    const [customDocument, setCustomDocument] = useState("");
    const [andOrCondition, setAndOrCondition] = useState("");
    const [checkActive, setCheckActive] = useState(false);
    const [userCheckBoxActive, setUserCheckBoxActive] = useState(false);
    const [progressBar, setProgressBar] = useState(0);
    const [emptydropDown, setEmptyDropDown] = useState(false);
    const [emptyFile, setEmptyFile] = useState(false);
    const [btnActive, SetBtnActive] = useState(true);
    const [documentPropertyCheckBox, setDocumentPropertyCheckBox] = useState(false);
    const [baseDocumentTypesList, setBaseDocumentTypesList] = useState([]);
    const [selectDocumentBaseTypeList, setSelectDocumentBaseTypeList] = useState([]);
    const [documentBaseTypeCheckBoxText, setDocumentBaseTypeCheckBoxText] = useState("");
    const fileInputRefs = useRef([]);

    const optionMultiSelect = [
        { label: 'To sale', value: '1' },
        { label: 'To rent', value: '2' },
        { label: 'Annuity sale', value: '3' },
    ];

    const {
        authUser,
        setAuthUser,
        isLoggedIn,
        setIsLoggedIn
    } = UseAuthContext();

    const location = useLocation();
    const navigate = useNavigate();

    const getNavigateState = (e) => {
        //selected office detail from whise
        setWhiseOfficeDetail(location.state.WhiseOffice);
        setLocalOfficeDetail(location.state.LocalOfficeDetail);
        setClientDetail(location.state.ClientDetail);
        setWhiseOfficesList(location.state.AllWhiseOffices);
        getListOfLayoutsByOffice(location.state.ClientDetail.localclient.client, "");
        getListOfTexteTemplates();

        if (location.state.TriggerDetail != undefined) {
            loadTriggerDetailInEdit(location.state.TriggerDetail);
        }
        else {
            //Bind saved survey Link on page load
            suveryLinkForEmail[1] = location.state.WhiseOffice.id;
            let link = [...suveryLinkForEmail];
            let html = link.map((item, i) => {
                if (i == 3) {
                    item = item.replace("&", "");
                    return item;
                }
                else {
                    return item;
                }
            }).join("");
            document.getElementById("inputSurveyLink").value = html;
        }

    }

    const getListOfLayoutsByOffice = async (_localclient, _savedlayout) => {
        if (_localclient == undefined) {
            _localclient = location.state.ClientDetail.localclient.client;
        }

        if (authUser == null) {
            return
        }

        const response = await fetch(variables.API_URL + 'Layout/GetLayoutsByClients?clientId=' + _localclient.clientid, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authUser.tokenValue}`,
                'Content-Type': 'application/json'
            }
        });

        const jsonData = await response.json();
        if (jsonData != null) {
            setOfficeLayout(jsonData);
            if (_savedlayout != undefined && _savedlayout != "") {
                setRecentlySavedLayout(_savedlayout);
            }
        }
    }

    const updateSelectedLayoutStates = (_layout) => {
        setSelectedLaytOutId(_layout.layoutid)
        setSelectedLaytName(_layout.layoutName);
        document.getElementById("layoutDropdown").value = _layout.layoutid;
    }

    //set trigger detail in form while editing a trigger
    const loadTriggerDetailInEdit = (trigger) => {
        setTriggerDetail(trigger);
        setFileName("file");
        setUserSelectDropDownName("text");
        setEmptyDropDown(false);
        setEmptyFile(false);
        document.getElementById("keymomentDropdown").value = trigger.keyMoment;
        let momentIndex = document.getElementById("keymomentDropdown").selectedIndex;
        let momentText = document.getElementById("keymomentDropdown")[momentIndex].text;
        triggerNameBuilder["keymomentDropdown"] = momentText;

        document.getElementById("triggertypeDropdown").value = trigger.triggerType;
        let triggertypeIndex = document.getElementById("triggertypeDropdown").selectedIndex;
        let triggertypeText = document.getElementById("triggertypeDropdown")[triggertypeIndex].text;
        triggerNameBuilder["triggertypeDropdown"] = triggertypeText;

        document.getElementById("durationtypeDropdown").value = trigger.durationType;
        let durationtypeIndex = document.getElementById("durationtypeDropdown").selectedIndex;
        let durationtypeText = document.getElementById("durationtypeDropdown")[durationtypeIndex].text;
        triggerNameBuilder["durationtypeDropdown"] = durationtypeText;

        if (trigger.durationValue != "") {
            document.getElementById("durationValue").removeAttribute("disabled");
            document.getElementById("durationValue").value = trigger.durationValue;
            triggerNameBuilder["durationValue"] = "(" + trigger.durationValue + ")";
        }
        document.getElementById("participent1").value = trigger.targetParticipant1;
        let participantIndex = document.getElementById("participent1").selectedIndex;
        let participantText = document.getElementById("participent1")[participantIndex].text;
        triggerNameBuilder["participent1"] = participantText;

        /*get multi select values from database */
        const fetchMultiSelectResult = trigger.transactionType.split(",")
        const filterData = optionMultiSelect.filter(el => fetchMultiSelectResult.includes(el.value));
        setSelectedProperty(filterData);

        const fetchIncludeUser = trigger.includedUsers.split(",");
        const filterIncludeUser = userList.filter(el => fetchIncludeUser.includes(el.value));
        setIncludeUserList(filterIncludeUser);

        const fetchExcludeUser = trigger.excludedUsers.split(",");
        const filterExcludeUser = userList.filter(el => fetchExcludeUser.includes(el.value));
        setExcuseUserList(filterExcludeUser);

        const customSubscriptionValue = trigger.customUnsubscription == null || trigger.customUnsubscription == "" ? false : trigger.customUnsubscription;
        setIsCustomUnsubscription(customSubscriptionValue)

        if (trigger.baseDocumentTypeList) {
            if (isNumbersSeparatedByCommas(trigger.baseDocumentTypeList)) {
                const fetchBaseDocumentTypeList = trigger.baseDocumentTypeList.split(",");
                const filterBaseDocumentTypeList = baseDocumentTypesList.filter(el => fetchBaseDocumentTypeList.includes(el.value));
                setSelectDocumentBaseTypeList(filterBaseDocumentTypeList);
            }
            else {
                const fetchBaseDocumentTypeList = JSON.parse(trigger.baseDocumentTypeList);
                setSelectDocumentBaseTypeList(fetchBaseDocumentTypeList);
            }
        }

        const customCheckBox = trigger.selectedDocumentTypeInEmail;
        if (customCheckBox == "") {
            setShowAllDetailsUser(false);
            setShowMappingDoc(false);
            setCheckActive(false);
            setUserCheckBoxActive(false);
            setCustomDocument("");
        } else {
            //setDocumentPropertyCheckBox(false);
            setShowAllDetailsUser(true);
            setShowMappingDoc(true);
            setCheckActive(true);
            setUserCheckBoxActive(true);
            setShowUserField(true);
            setCustomDocument("custom document");
        }

        const selectDocumentBaseTypeChecked = trigger.selectedBaseDocumentType;
        if (selectDocumentBaseTypeChecked != "property document") {
            setDocumentPropertyCheckBox(false);
            setDocumentBaseTypeCheckBoxText("");
            setSelectDocumentBaseTypeList([]);
        } else {
            setDocumentPropertyCheckBox(true);
            setDocumentBaseTypeCheckBoxText(selectDocumentBaseTypeChecked);
        }

        if (userListDropDown != "") {
            const docMappingValueConvertArr = JSON.parse(trigger.documentMappingRule);
            const transformedUserRows = docMappingValueConvertArr.map((item, index) => {
                return {
                    id: index + 1,
                    userId: item.value.userId,
                    dropdowns: item.value.userId2 ? [{ id: 1, userId: item.value.userId2, isUserIdDropdownEmpty: false }] : [],
                    showPlusIcon: item.value.condition == "" ? true : false,
                    file: item.value.file,
                    en: item.value.en,
                    fr: item.value.fr,
                    nl: item.value.nl,
                    progressBarActive: true,
                    progressBar: 0,
                    isUserIdDropdownEmpty: false,
                    isFileFieldEmpty: false,
                    isFrEmpty: false,
                    isEnEmpty: false,
                    isNlEmpty: false,
                    pdfViewer: item.value.pdfViewer != undefined ? item.value.pdfViewer : false
                };
            });
            setUserRows(transformedUserRows);
        }

        //populate trigger name from database and fill state to handle controls state
        setFinalTriggerName(trigger.triggerName);

        //setSelectedTarget(targets);
        setSelectedTab(trigger.language);

        const transactionStatus = document.getElementById("transactionStatus");

        /* transactionType.value = trigger.transactionType;*/
        transactionStatus.value = trigger.transactionStatus;


        setEmailTexteEnglish(trigger.texteEnglish);
        setEmailTexteFrench(trigger.texteFrench);
        setEmailTexteDutch(trigger.texteDutch);

        document.getElementById("addTrigger").innerHTML = "Edit Trigger";

        if (trigger.contactPreference != '') {
            setContactPreference(trigger.contactPreference);
        }
    }

    const isNumbersSeparatedByCommas = (str) => {
        // Regular expression to match numbers separated by commas
        const regex = /^\d+(,\d+)*$/;
        return regex.test(str);
    }

    const populateSurveyLinkInEditMode = (_link) => {
        //splitting survey link to mark checkboxes checked when opening trigger is in edit mode
        const splittedSurveyLink = _link.split("/");
        splittedSurveyLink[1] += "//";

        let firstPart = splittedSurveyLink[0] + splittedSurveyLink[1] + splittedSurveyLink[2] + "/";
        let secondPart = splittedSurveyLink[3];
        let thirdPart = "/?";
        let remainingString = splittedSurveyLink[4].replace("?", "");
        let initialLinkStateArray = [];
        Array.prototype.push.apply(initialLinkStateArray, [firstPart, secondPart, thirdPart]);

        let savedParams = remainingString.split("&");
        savedParams.forEach((item, i) => {
            initialLinkStateArray.push("&" + item);
        })

        if (remainingString.indexOf("agent") != -1) {
            document.getElementById("checkAgent").checked = true;
        }
        if (remainingString.indexOf("profile=buyer") != -1) {
            document.getElementById("checkBuyer").checked = true;
        }
        if (remainingString.indexOf("profile=tenant") != -1) {
            document.getElementById("checkTenant").checked = true;
        }
        if (remainingString.indexOf("name={n") != -1) {
            document.getElementById("checkName").checked = true;
        }
        if (remainingString.indexOf("Firstname={f") != -1) {
            document.getElementById("checkFirstname").checked = true;
        }
        if (remainingString.indexOf("language") != -1) {
            document.getElementById("checkLanguage").checked = true;
        }
        if (remainingString.indexOf("profile=vendor") != -1) {
            document.getElementById("checkVendor").checked = true;
        }
        if (remainingString.indexOf("zip") != -1) {
            document.getElementById("checkZip").checked = true;
        }
        if (remainingString.indexOf("lessorestimation") != -1) {
            document.getElementById("checkEstimation").checked = true;
        }
        if (remainingString.indexOf("email") != -1) {
            document.getElementById("checkEmail").checked = true;
        }
        if (remainingString.indexOf("country") != -1) {
            document.getElementById("checkCountry").checked = true;
        }
        if (remainingString.indexOf("npssatisfication") != -1) {
            document.getElementById("checkSatisfaction").checked = true;
        }
        if (remainingString.indexOf("officeID") != -1) {
            document.getElementById("checkOfficeid").checked = true;
        }
        if (remainingString.indexOf("loginOfficeID") != -1) {
            document.getElementById("checkLoginofficeid").checked = true;
        }
        if (remainingString.indexOf("contactID") != -1) {
            document.getElementById("checkContactid").checked = true;
        }
        if (remainingString.indexOf("profile=lessor") != -1) {
            document.getElementById("checkLessor").checked = true;
        }
        setSuveryLinkForEmail(initialLinkStateArray);

        document.getElementById("inputSurveyLink").value = _link;
    }

    const setNameForTrigger = (e) => {

        if (e.target.value != "") {

            if (e.target.style.borderColor == "red") {
                e.target.style.borderColor = "#ced4da";
            }
            let index = e.target.selectedIndex;
            let value = e.target[index].text;
            triggerNameBuilder[e.target.id] = value;

            let nameString = ""
            Object.keys(triggerNameBuilder).map(item => {
                nameString += triggerNameBuilder[item] + " ";
            })
            setFinalTriggerName(nameString);

            //unchecked survey link checkboxes dynamically when keymoment dropdown get changed
            let _existinglink = [...suveryLinkForEmail];
            let surveyLinkParametersArray = _existinglink.slice(0, 3);

            if (e.target.id != "keymomentDropdown") {
                return;
            }

            document.getElementById("checkAgent").checked = false;
            document.getElementById("checkBuyer").checked = false;
            document.getElementById("checkTenant").checked = false;
            document.getElementById("checkName").checked = false;
            document.getElementById("checkFirstname").checked = false;
            document.getElementById("checkLanguage").checked = false;
            document.getElementById("checkVendor").checked = false;
            document.getElementById("checkZip").checked = false;
            document.getElementById("checkEstimation").checked = false;
            document.getElementById("checkEmail").checked = false;
            document.getElementById("checkCountry").checked = false;
            document.getElementById("checkSatisfaction").checked = false;
            document.getElementById("checkOfficeid").checked = false;
            document.getElementById("checkLoginofficeid").checked = false;
            document.getElementById("checkContactid").checked = false;
            document.getElementById("checkLessor").checked = false;

            if (location.state.TriggerDetail != undefined && location.state.TriggerDetail.keyMoment == e.target.value) {
                //populateSurveyLinkInEditMode(location.state.TriggerDetail.surveyLink);
                return
            }

            if (e.target.value == "1") {
                document.getElementById("checkName").checked = true;
                document.getElementById("checkBuyer").checked = true;
                document.getElementById("checkFirstname").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkName"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkBuyer"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkFirstname"), surveyLinkParametersArray);
            }
            if (e.target.value == "2") {
                document.getElementById("checkBuyer").checked = true;
                document.getElementById("checkName").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkBuyer"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkName"), surveyLinkParametersArray);
            }
            if (e.target.value == "3") {
                document.getElementById("checkOfficeid").checked = true;
                document.getElementById("checkContactid").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkOfficeid"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkContactid"), surveyLinkParametersArray);
            }
            if (e.target.value == "4") {
                document.getElementById("checkVendor").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkVendor"), surveyLinkParametersArray);
            }
            if (e.target.value == "5") {
                document.getElementById("checkEstimation").checked = true;
                document.getElementById("checkCountry").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkEstimation"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
            }
            if (e.target.value == "6") {
                document.getElementById("checkEstimation").checked = true;
                document.getElementById("checkSatisfaction").checked = true;
                document.getElementById("checkTenant").checked = true;
                document.getElementById("checkCountry").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkEstimation"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkSatisfaction"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkTenant"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
            }
            if (e.target.value == "7") {
                document.getElementById("checkZip").checked = true;
                document.getElementById("checkLanguage").checked = true;
                document.getElementById("checkLessor").checked = true;
                document.getElementById("checkCountry").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkZip"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkLanguage"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkLessor"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
            }
            if (e.target.value == "8") {
                document.getElementById("checkZip").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkZip"), surveyLinkParametersArray);
            }
            if (e.target.value == "9") {
                document.getElementById("checkEstimation").checked = true;
                document.getElementById("checkTenant").checked = true;
                document.getElementById("checkCountry").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkEstimation"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkTenant"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
            }
            if (e.target.value == "10") {
                document.getElementById("checkTenant").checked = true;
                document.getElementById("checkCountry").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkTenant"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
            }
            if (e.target.value == "11") {
                document.getElementById("checkZip").checked = true;
                document.getElementById("checkLanguage").checked = true;
                document.getElementById("checkLessor").checked = true;
                document.getElementById("checkCountry").checked = true;
                document.getElementById("checkFirstname").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkZip"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkLanguage"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkLessor"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkFirstname"), surveyLinkParametersArray);
            }
            if (e.target.value == "12") {
                document.getElementById("checkCountry").checked = true;
                document.getElementById("checkFirstname").checked = true;
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkCountry"), surveyLinkParametersArray);
                surveyLinkParametersArray = generateSurveyLinkOnKeyMomentDropdownChange(document.getElementById("checkFirstname"), surveyLinkParametersArray);
            }

            let finalSurveyLink = surveyLinkParametersArray.map((item, i) => {
                if (i == 3) {
                    item = item.replace("&", "");
                    return item;
                }
                else {
                    return item;
                }
            }).join("")

            document.getElementById("inputSurveyLink").value = finalSurveyLink;

            setSuveryLinkForEmail(current => surveyLinkParametersArray);
        }
        else {
            triggerNameBuilder[e.target.id] = "";
            let nameString = ""
            Object.keys(triggerNameBuilder).map(item => {
                nameString += triggerNameBuilder[item] + " ";
            })
            setFinalTriggerName(nameString);
        }
    }

    //push selected checkbox value in survey link parameter array and return updated array
    const generateSurveyLinkOnKeyMomentDropdownChange = (e, _linkArray) => {
        let checked = e.checked;
        let placeholder = e.getAttribute("placeholder");
        let paramName = e.getAttribute("parameter");

        let symbol = "&";

        if (checked == true) {
            let finalValue = symbol + paramName + "=" + placeholder;
            const existingParam = _linkArray.filter(item => {
                return item == finalValue;
            })
            if (existingParam.length == 0) {
                _linkArray.push(finalValue);
            }
        }
        else {
            let finalValue = symbol + paramName + "=" + placeholder;
            const indexOfItem = _linkArray.indexOf(finalValue);
            _linkArray.splice(indexOfItem, 1);
        }

        return _linkArray;
    }

    const resetConditionDropdowns = (e) => {
        let value = e.target.value;
        if (value != "") {
            if (e.target.style.borderColor == "red") {
                e.target.style.borderColor = "#ced4da";
            }
        }
    }

    const onChangeOfDurationType = (e) => {
        let value = e.target.value;
        if (value != "") {
            document.getElementById("durationValue").removeAttribute("disabled");

            if (e.target.style.borderColor == "red") {
                e.target.style.borderColor = "#ced4da";
            }

            let index = e.target.selectedIndex;
            let value = e.target[index].text;
            triggerNameBuilder[e.target.id] = value;

            let nameString = ""
            Object.keys(triggerNameBuilder).map(item => {
                nameString += triggerNameBuilder[item] + " ";
            })

            setFinalTriggerName(nameString);
        }
        else {
            document.getElementById("durationValue").value = "";
            document.getElementById("durationValue").setAttribute("disabled", true);

            triggerNameBuilder["durationValue"] = "";
            triggerNameBuilder[e.target.id] = "";

            let nameString = ""
            Object.keys(triggerNameBuilder).map(item => {
                nameString += triggerNameBuilder[item] + " ";
            })

            setFinalTriggerName(nameString);
        }
    }

    //const appendMultiselectValuesInTriggerName = (targets) => {

    //    if (targets.length > 0) {
    //        let targetString = "";
    //        targets.forEach(item => {
    //            targetString += item.label + " ";
    //        })
    //        triggerNameBuilder["ctarget1"] = targetString;
    //        let nameString = ""
    //        Object.keys(triggerNameBuilder).map(item => {
    //            nameString += triggerNameBuilder[item] + " ";
    //        })

    //        setFinalTriggerName(nameString);
    //    }
    //    else {
    //        triggerNameBuilder["ctarget1"] = "";
    //        let nameString = ""
    //        Object.keys(triggerNameBuilder).map(item => {
    //            nameString += triggerNameBuilder[item] + " ";
    //        })

    //        setFinalTriggerName(nameString);
    //    }

    //}

    //const setListOfTargetType = (e) => {

    //    setSelectedTarget(e);
    //    appendMultiselectValuesInTriggerName(e); //append selected c-targets in trigger name

    //    const _targers = e;

    //    _targers.forEach(traget => {
    //        if (participentType.indexOf(traget.value) == -1) {
    //            participentType.push(traget.label);
    //        }
    //    })

    //    let html = ""
    //    document.getElementById("surveyTypeCheckboxes").innerHTML = "";

    //    html += `<label class="me-3 mb-0 fw-bold">Survey Email:</label>`;
    //    html += `<div class="form-check form-check-inline me-2">
    //                <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineParticipent" />
    //                <label class="form-check-label mb-0" for="inlineParticipent" >Participent</label>
    //            </div>`

    //    participentType.forEach((item) => {
    //        html += `<div class="form-check form-check-inline me-2">
    //                    <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inline${item}" />
    //                    <label class="form-check-label mb-0" for="inline${item}" >${item}</label>
    //                </div>`
    //    })

    //    document.getElementById("surveyTypeCheckboxes").innerHTML += html;
    //}

    const OnChangeHandlerEmailLayout = (e) => {
        let value = e.target.value;
        if (value != "") {
            if (e.target.style.borderColor == "red") {
                e.target.style.borderColor = "#ced4da";
            }
            setSelectedLaytOutId(+value);
            var index = e.target.selectedIndex;
            setSelectedLaytName(e.target[index].text);
        }
        else {
            setSelectedLaytName("");
        }
    }

    const hideLayoutModal = (e) => {
        setShowLayoutModal(false);

        if (e == undefined) {
            if (layoutModalType == "new") {
                let layoutDropdown = document.getElementById("layoutDropdown");
                let layoutId = layoutDropdown.value;
                if (layoutId != "") {
                    let selectedIndex = layoutDropdown.selectedIndex;
                    setSelectedLaytOutId(layoutId);
                    setSelectedLaytName(layoutDropdown[selectedIndex].text);
                }
            }
        }
    }

    const openLayoutModal = (e) => {
        if (e.target.innerText == "New") {
            setSelectedLaytOutId(0);
            setSelectedLaytName("");
            setShowLayoutModal(true);
            setLayoutModalTitle("New Layout");
            setLayoutModalType("new");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
        else if (e.target.innerText == "Edit") {
            if (document.getElementById("layoutDropdown").value == "") {
                alert("No layout selected for edit");
                return
            }
            setShowLayoutModal(true);
            setLayoutModalTitle(selectedLaytName + " Edit Layout");
            setLayoutModalType("edit");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
        else {
            if (document.getElementById("layoutDropdown").value == "") {
                alert("No layout selected for preview");
                return
            }
            setShowLayoutModal(true);
            setLayoutModalTitle(selectedLaytName + " Layout Preview");
            setLayoutModalType("preview");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
    }

    const handleChangeSelected = (item) => {
        setSelectedProperty(item)
    }

    const getListOfTexteTemplates = async (_savedTexteTemplate) => {

        if (authUser == null) {
            return
        }
        const response = await fetch(variables.API_URL + 'TexteTemplate/GetAllTexteTemplates', {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${authUser.tokenValue}`,
                'Content-Type': 'application/json'
            }
        });

        const jsonData = await response.json();
        if (jsonData != null) {
            setTexteTemplateData(jsonData);
            if (_savedTexteTemplate != undefined) {
                setRecentlySavedTexteTemplate(_savedTexteTemplate);
            }
        }
    }

    const updateTexteTemplateState = (_texteLayout) => {
        setSelectedTexteId(_texteLayout.templateId);
        setSelectedTexteName(_texteLayout.templateName);
        document.getElementById("texteDropdown").value = _texteLayout.templateId;
    }

    const setSelectedEmailLayoutTexte = (e) => {
        let value = e.target.value;
        if (value != "") {
            if (e.target.style.borderColor == "red") {
                e.target.style.borderColor = "#ced4da";
            }
            setSelectedTexteId(value);
            var index = e.target.selectedIndex;
            setSelectedTexteName(e.target[index].text);
        }
        else {
            setSelectedTexteName("");
        }
    }

    const openLayoutModalTexte = (e) => {
        if (e.target.innerText == "New") {
            setSelectedTexteId("0");
            setSelectedTexteName("");
            setShowTexteModal(true);
            setTexteModalTitle("New Texte Template");
            setTexteModalType("new");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
        else if (e.target.innerText == "Edit") {
            if (document.getElementById("texteDropdown").value == "") {
                alert("No texte selected for edit");
                return;
            }
            setShowTexteModal(true);
            setTexteModalTitle(selectedTexteName + " Edit");
            setTexteModalType("edit");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
        else {
            if (document.getElementById("texteDropdown").value == "") {
                alert("No texte selected for preview");
                return;
            }
            setShowTexteModal(true);
            setTexteModalTitle(selectedTexteName + " Preview");
            setTexteModalType("preview");
            setOfficeId(localOfficeDetail.officeid);
            setClientId(clientDetail.localclient.client.clientid);
        }
    }

    const hideTexteModal = (e) => {
        setShowTexteModal(false);
        if (e == undefined) {
            if (texteModalType == "new") {
                let texteDropdown = document.getElementById("texteDropdown");
                let texteId = texteDropdown.value;
                if (texteId != "") {
                    let selectedIndex = texteDropdown.selectedIndex;
                    setSelectedTexteId(texteId);
                    setSelectedTexteName(texteDropdown[selectedIndex].text);
                }
            }
        }
    }

    /*   End New code from Abdul Saboor */

    const handleTabSelect = (e) => {
        setSelectedTab(e);
    }

    const replaceTriorFromLink = (e) => {
        const value = e.target.value;
        const splitedValue = value.split("/");

        const triorReplacement = splitedValue[3];

        let link = [...suveryLinkForEmail];
        link[1] = triorReplacement;
        setSuveryLinkForEmail(current => link);
    }

    const generateSurveyLink = (e) => {
        let checked = e.target.checked;
        let placeholder = e.target.getAttribute("placeholder");
        let paramName = e.target.getAttribute("parameter");
        let link = [...suveryLinkForEmail];
        let symbol = "&";

        if (checked == true) {
            let finalValue = symbol + paramName + "=" + placeholder;
            const existingParam = link.filter(item => {
                return item == finalValue;
            })
            if (existingParam.length == 0) {
                link.push(finalValue);
            }
        }
        else {
            let finalValue = symbol + paramName + "=" + placeholder;
            const indexOfItem = link.indexOf(finalValue);
            link.splice(indexOfItem, 1);
        }

        let html = link.map((item, i) => {
            if (i == 3) {
                item = item.replace("&", "");
                return item;
            }
            else {
                return item;
            }
        }).join("")

        document.getElementById("inputSurveyLink").value = html;

        setSuveryLinkForEmail(current => link);
    }

    const saveTrigger = (e) => {
        e.target.setAttribute("disabled", true);
        document.querySelector("body").style.cursor = "progress";
        let isRequiredFieldsEmpty = false;
        const keymomentDropdown = document.getElementById("keymomentDropdown");
        const triggerTypeDropdown = document.getElementById("triggertypeDropdown");
        const durationtypeDropdown = document.getElementById("durationtypeDropdown");
        const durationValue = document.getElementById("durationValue");
        const whiseOptions = document.getElementById("whiseAppointmentType");
        const transactionType = document.getElementById("transactionType");
        const transactionStatus = document.getElementById("transactionStatus");
        const englishSubject = "";// document.getElementById("texteEngSubject");
        const frenchSubject = "";// document.getElementById("texteFrSubject");
        const dutchSubject = "";// document.getElementById("texteDuSubject");
        const targetParticipent = document.getElementById("participent1");
        const selectedTexteOption = document.getElementById("texteDropdown");
        const layoutDropdown = document.getElementById("layoutDropdown");
        const baseDocumentTypeDropdow = document.querySelector(".documentBaseTypeMutliList");

        if (keymomentDropdown.value == "") {
            keymomentDropdown.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (triggerTypeDropdown.value == "") {
            triggerTypeDropdown.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (durationtypeDropdown.value == "") {
            durationtypeDropdown.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (durationValue.value == "") {
            durationValue.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (targetParticipent.value == "") {
            targetParticipent.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (whiseOptions.value == "") {
            whiseOptions.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (layoutDropdown.value == "") {
            layoutDropdown.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }
        if (selectedTexteOption.value == "") {
            selectedTexteOption.style.borderColor = "red";
            isRequiredFieldsEmpty = true;
        }

        let mappedUsers = userRows.map(el => {
            let isUserId1Empty = false;
            let isUserId2Empty = false;

            if (el.userId == "") {
                isRequiredFieldsEmpty = true;
                el.isUserIdDropdownEmpty = true;
            }
            if (el.dropdowns.length > 0) {
                let dropdowns = el.dropdowns.map(d => {
                    if (d.userId == "") {
                        isRequiredFieldsEmpty = true;
                        d.isUserIdDropdownEmpty = true;
                    }
                    return d;
                })
                el.dropdowns = dropdowns;
            }

            if (el.file == null) {
                isRequiredFieldsEmpty = true;
                el.isFileFieldEmpty = true;
            }
            if (el.fr == "") {
                isRequiredFieldsEmpty = true;
                el.isFrEmpty = true;
            }
            if (el.en == "") {
                isRequiredFieldsEmpty = true;
                el.isEnEmpty = true;
            }
            if (el.nl == "") {
                isRequiredFieldsEmpty = true;
                el.isNlEmpty = true;
            }
            return el;
        })

        setUserRows(mappedUsers);

        if (documentPropertyCheckBox == true) {
            if (selectDocumentBaseTypeList.length == 0) {
                baseDocumentTypeDropdow.style.border = "1px solid red";
                baseDocumentTypeDropdow.style.borderRadius = "7px";
                isRequiredFieldsEmpty = true;
            }
        }

        if (isRequiredFieldsEmpty == true) {
            alert("Please fill the required fields");
            e.target.removeAttribute("disabled");
            document.querySelector("body").style.cursor = "default";
            return
        }

        //array convert into string and store in database 
        const getMultiValue = selectedProperty;
        let getFinalMultiSelectValue = "";
        const loopLength = (getMultiValue.length) - 1;
        if (getMultiValue != "") {
            for (let i = 0; i < getMultiValue.length; i++) {
                if (i == loopLength) {
                    getFinalMultiSelectValue += getMultiValue[i].value;
                } else {
                    getFinalMultiSelectValue += getMultiValue[i].value + ",";
                }

            }
        }

        const getIncludeUserList = includeUserList;
        let getFinalIncludeUserList = "";
        const loopLengthIncludeUser = (getIncludeUserList.length) - 1;
        if (getIncludeUserList != "") {
            for (let i = 0; i < getIncludeUserList.length; i++) {
                if (i == loopLengthIncludeUser) {
                    getFinalIncludeUserList += getIncludeUserList[i].value;
                } else {
                    getFinalIncludeUserList += getIncludeUserList[i].value + ",";
                }
            }
        }

        const getExcludeUserList = excuseUserList;
        let getFinalExcludeUserList = "";
        const loopLengthExcludeUser = (getExcludeUserList.length) - 1;
        if (getExcludeUserList != "") {
            for (let i = 0; i < getExcludeUserList.length; i++) {
                if (i == loopLengthExcludeUser) {
                    getFinalExcludeUserList += getExcludeUserList[i].value;
                } else {
                    getFinalExcludeUserList += getExcludeUserList[i].value + ",";
                }
            }
        }

        let getFinalDocBaseTypeList = documentPropertyCheckBox ? JSON.stringify(selectDocumentBaseTypeList) : "";

        //const getDocBaseTypeList = documentPropertyCheckBox ? selectDocumentBaseTypeList : [];
        //let getFinalDocBaseTypeList = "";
        //const loopLengthDocBaseList = (getDocBaseTypeList.length) - 1;
        //if (getDocBaseTypeList != "") {
        //    for (let i = 0; i < getDocBaseTypeList.length; i++) {
        //        if (i == loopLengthDocBaseList) {
        //            getFinalDocBaseTypeList += getDocBaseTypeList[i].value;
        //        } else {
        //            getFinalDocBaseTypeList += getDocBaseTypeList[i].value + ",";
        //        }
        //    }
        //}

        const docMappingRule = userRows.map((row) => {
            const condition = row.dropdowns.length ? "and" : "";
            return {
                row: row.id,
                value: {
                    userId: row.userId,
                    userId2: row.dropdowns.length > 0 ? row.dropdowns[0].userId : "",
                    condition: condition,
                    file: row.file,
                    en: row.en,
                    fr: row.fr,
                    nl: row.nl,
                    pdfViewer: row.pdfViewer
                }
            };
        });

        const document_mapping_convert_string = JSON.stringify(docMappingRule);

        //configurations to post json data
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${authUser.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        //SaveOfficeTriggerDetail api call
        //Api call to save trigger for an office 
        let language = selectedTab;
        let trigger = {};
        let objOfficeTrigger = {
            OfficeTriggerid: triggerDetail.officeTriggerid != undefined ? triggerDetail.officeTriggerid : 0,
            Officeid: +localOfficeDetail.officeid,
            WhiseOfficeid: whiseOfficeDetail.id,
            WhiseClientid: clientDetail.id,
            Layoutid: +selectedLaytOutId,
            TriggerName: finalTriggerName,
            KeyMoment: keymomentDropdown.value,
            TriggerType: triggerTypeDropdown.value,
            DurationType: durationtypeDropdown.value,
            DurationValue: +durationValue.value,
            TargetParticipant1: targetParticipent.value,
            CTarget1: JSON.stringify(selectedTarget), //document.getElementById("ctarget1").value,
            TargetParticipant2: "",
            CTarget2: "",
            Language: language,
            EnglishSubject: "",//englishSubject.value,
            TexteEnglish: "",//emailTexteEnglish,
            FrenchSubject: "",//frenchSubject.value,
            TexteFrench: "",//emailTexteFrench,
            DutchSubject: "",//dutchSubject.value,
            TexteDutch: "",//emailTexteDutch,
            AppointmentType: whiseOptions.value,
            TransactionType: getFinalMultiSelectValue,//transactionType.value, 
            TransactionStatus: transactionStatus.value,
            SurveyLink: "",//document.getElementById("inputSurveyLink").value,
            ContactPreference: contactPreference,
            texteTemplateId: +selectedTexteId,
            IncludedUsers: getFinalIncludeUserList,
            ExcludedUsers: getFinalExcludeUserList,
            SelectedDocumentTypeInEmail: customDocument,
            DocumentMappingRule: document_mapping_convert_string,
            SelectedBaseDocumentType: documentBaseTypeCheckBoxText,
            BaseDocumentTypeList: getFinalDocBaseTypeList,
            CustomUnsubscription: isCustomUnsubscription
        }
        let triggerurl = variables.API_URL + `OfficeTrigger/SaveOfficeTriggerDetail?`;
        return axios.post(triggerurl, JSON.stringify(objOfficeTrigger), jsonconfig)
            .then((response) => {
                alert("Trigger successfully saved.");
                e.target.removeAttribute("disabled");
                document.querySelector("body").style.cursor = "default";
                moveBackToOfficeScreen();
            })
            .catch(error => {
                alert('Error fetching data:', error);
            });
    }

    const moveBackToOfficeScreen = (e) => {
        const stateBuilder = {
            AllWhiseOffices: whiseOfficesList,
            WhiseOffice: whiseOfficeDetail,
            CurrentClient: clientDetail,
            LocalOffice: localOfficeDetail,
            LocalOfficesList: location.state.LocalOfficesList,
        }

        const url = "/officesettings/" + whiseOfficeDetail.id;
        navigate(url, {
            state: stateBuilder
        });
    }

    const contactPreferenceChangeHandler = (e) => {
        const checkedOption = e.target.getAttribute("preferencevalue");
        setContactPreference(checkedOption);
    }

    const changeHandlerCustomUnsubscription = (e) => {
        const isChecked = e.target.checked;
        setIsCustomUnsubscription(isChecked);
    }

    const handleEditorChangeEnglish = (content) => {
        setEmailTexteEnglish(content);
    };

    const handleEditorChangeFrench = (content) => {
        setEmailTexteFrench(content);
    }

    const handleEditorChangeDutch = (content) => {
        setEmailTexteDutch(content);
    }

    const DeleteLayoutHandler = (e) => {
        if (e.target.innerText == "Delete") {
            if (document.getElementById("layoutDropdown").value == "") {
                alert("No layout selected for delete");
                return
            }
            setShowModalDelete(true);
            setDeleteModalTitle("Delete Layout");
            setDeleteModalType("deletelayout");
            let layouts = officeLayout;

            let filteredLayouts = layouts.filter(e => {
                return e.layoutid != selectedLaytOutId;
            })
            setLayoutsListWithoutCurrentLayout(filteredLayouts);
        }
    }

    const updateLayoutStatesAfterDelete = (updatedLayoutId, updatedLayoutName) => {
        let currentTriggerDetail = triggerDetail;
        if (updatedLayoutId !== "") {
            getUpdatedTriggerDetail();
            //setSelectedLaytOutId(updatedLayoutId)
            //setSelectedLaytName(updatedLayoutName);
            //document.getElementById("layoutDropdown").value = updatedLayoutId;
            //currentTriggerDetail.layoutid = updatedLayoutId;
            //setTriggerDetail(currentTriggerDetail);
        }
        else {
            setSelectedLaytOutId(0)
            setSelectedLaytName("");
            document.getElementById("layoutDropdown").value = "";
        }
    }

    const hideModalDelete = (e) => {
        setShowModalDelete(false);
    }

    const getUpdatedTriggerDetail = () => {
        const jsonconfig = {
            headers: {
                'Authorization': `Bearer ${authUser.tokenValue}`,
                'Content-Type': 'application/json'
            }
        };
        const url = variables.API_URL + `OfficeTrigger/GetOfficeTriggerDetail?triggerId=` + triggerDetail.officeTriggerid;
        axios.get(url, jsonconfig) // ASP.NET Core API endpoint with headers
            .then(response => {
                const stateBuilder = {
                    LocalOfficeDetail: location.state.LocalOfficeDetail,
                    WhiseOffice: location.state.WhiseOffice,
                    ClientDetail: location.state.ClientDetail,
                    AllWhiseOffices: location.state.AllWhiseOffices,
                    LocalOfficesList: location.state.LocalOfficesList
                }
                if (response.data != undefined) {
                    stateBuilder.TriggerDetail = response.data;
                }

                const url = "/trigger/" + localOfficeDetail.officeid;
                navigate(url, {
                    state: stateBuilder
                })
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }


    // new methods for texte Delete ConfirmationModal

    const deletetextehandler = (e) => {
        if (e.target.innerText == "Delete") {
            if (document.getElementById("texteDropdown").value == "") {
                alert("No texte selected for delete");
                return
            }

            setShowModalTexteDelete(true);
            setDeleteModalTexteTitle("Delete Texte Template");
            setDeleteModalTexteType("deletetexte");

            let textes = texteTemplateData;

            let filteredtextes = textes.filter(e => {
                return e.templateId != selectedTexteId;
            })
            setTexteListByWithoutCurrentTexte(filteredtextes);
        }
    }

    const hidetexteModalDelete = (e) => {
        setShowModalTexteDelete(false)
    }

    const updateTexeteStatesAfterDelete = (updatedTexteId) => {

        let currentTriggerDetail = triggerDetail;
        if (updatedTexteId != "") {

            getUpdatedTriggerDetail();

        } else {
            setSelectedTexteId("0")
            setSelectedTexteName("");
            document.getElementById("texteDropdown").value = "0";
        }
    }

    const pdfViewerCheckBoxActive = (e) => {
        const rowIndex = e.target.getAttribute("elementindex");
        const isChecked = e.target.checked;
        const existingRows = [...userRows];

        if (isChecked) {
            existingRows[rowIndex].pdfViewer = isChecked;
        }
        else {
            existingRows[rowIndex].pdfViewer = isChecked;
        }
    }

    const baseDocPdfViewerCheckBoxActive = (e) => {
        const rowIndex = e.target.getAttribute("elementindex");
        const isChecked = e.target.checked;
        const existingRows = [...selectDocumentBaseTypeList];

        if (isChecked) {
            existingRows[rowIndex].pdfViewer = isChecked;
        }
        else {
            existingRows[rowIndex].pdfViewer = isChecked;
        }
    }

    // add new code abdul saboor check box active
    const checkBoxActive = (event) => {
        const checkBoxId = event.target.id;
        const isChecked = event.target.checked;

        if (isChecked) {
            if (checkBoxId == "customDocCheckBox") {
                setShowAllDetailsUser(true);
                setShowMappingDoc(true);
                setCheckActive(true);
                setCustomDocument("custom document");
            } else if (checkBoxId == "userIdCheckBox") {
                setShowUserField(true);
                setUserCheckBoxActive(true);
                addNewUserRow();
            }
        } else {
            if (checkBoxId == "customDocCheckBox") {
                setShowAllDetailsUser(false);
                setShowMappingDoc(false);
                setCheckActive(false);
                setCustomDocument("");
                setShowUserField(false);
                setUserCheckBoxActive(false);
                setUserRows([]);
            } else if (checkBoxId == "userIdCheckBox") {
                setShowUserField(false);
                setUserCheckBoxActive(false);
                setUserRows([]);
            }
        }
    }

    const documentBaseTypeCheckBoxHandle = (event) => {
        const checkBoxId = event.target.id;
        const isChecked = event.target.checked;

        if (isChecked) {
            if (checkBoxId == "documentPropertyCheckBox") {
                setDocumentPropertyCheckBox(true);
                setDocumentBaseTypeCheckBoxText("property document");
            }
        } else {
            if (checkBoxId == "documentPropertyCheckBox") {
                setDocumentPropertyCheckBox(false);
                setDocumentBaseTypeCheckBoxText("");
                setSelectDocumentBaseTypeList([]);
            }
        }

    }

    const addNewUserRow = () => {
        const generateUniqueId = () => {
            let newId = 1;
            const existingIds = userRows.map(row => row.id);
            while (existingIds.includes(newId)) {
                newId++;
            }
            return newId;
        };

        const newId = generateUniqueId();

        setUserRows([...userRows, { id: newId, userSelectValue: "", dropdowns: [], showPlusIcon: true, file: null, progressBarActive: true, progressBar: 0, userId: "", fr: "", en: "", nl: "", isUserIdDropdownEmpty: false, isFileFieldEmpty: false, isFrEmpty: false, isEnEmpty: false, isNlEmpty: false, pdfViewer: false }]);
    };

    const addNewUserDropDown = (rowIndex) => {
        const updatedUserRows = userRows.map((row, index) => {
            if (rowIndex === index) {
                if (row.dropdowns.length >= 1) {
                    return {
                        ...row,
                        showPlusIcon: false,
                    }
                } else {
                    return {
                        ...row,
                        dropdowns: [
                            ...row.dropdowns,
                            { id: row.dropdowns.length + 1, userSelectValue: "", userId: "", isUserIdDropdownEmpty: false }
                        ],
                        showPlusIcon: false,
                    };
                }
            }
            return row;
        });
        setUserRows(updatedUserRows);
        setAndOrCondition("and");
    }

    const removeUserdropdown = (rowIndex, dropdownId) => {
        const updatedUserRows = userRows.map((row, index) => {
            if (rowIndex === index) {
                const updatedDropdowns = row.dropdowns.filter(dropdown => dropdown.id !== dropdownId);
                return {
                    ...row,
                    dropdowns: updatedDropdowns,
                    showPlusIcon: updatedDropdowns.length < 2 // Show plus icon if less than 2 dropdowns
                }
            }
            return row;
        });
        setUserRows(updatedUserRows);
    }

    const removeUserRow = (e) => {
        let rowIndex = e.target.getAttribute("elementindex");
        if (e.target.nodeName == "path") {
            rowIndex = e.target.parentElement.getAttribute("elementindex");
        }
        let fileUsers = userRows.filter((el, index) => {
            return index != +rowIndex;
        });
        //fileUsers.splice(rowIndex, 1);
        //const removeDropdown = userRows.filter((item) => item.id != rowId);
        setUserRows(fileUsers);
    }

    const handleLanguageChange = (e) => {
        let rowIndex = e.target.getAttribute("elementindex");
        let language = e.target.getAttribute("language");

        let fileUsers = userRows.map((el, index) => {
            if (index == rowIndex) {
                if (language == "en") {
                    el.en = e.target.value;
                    if (e.target.value != "") {
                        el.isEnEmpty = false;
                    }
                }
                if (language == "fr") {
                    el.fr = e.target.value;
                    if (e.target.value != "") {
                        el.isFrEmpty = false;
                    }
                }
                if (language == "nl") {
                    el.nl = e.target.value;
                    if (e.target.value != "") {
                        el.isNlEmpty = false;
                    }
                }
            }
            return el;
        });
        setUserRows(fileUsers);
    }

    const handleIncludeUser = (item) => {
        setIncludeUserList(item)
    }

    const handleExcuseUser = (item) => {
        setExcuseUserList(item)
    }

    const handleIconClick = (index) => {
        fileInputRefs.current[index].click();
    };

    const handleFileChange = (event) => {
        let rowIndex = parseInt(event.target.getAttribute("elementindex"));

        SetBtnActive(false);

        let fileUsers = userRows.map((el, ind) => {
            if (ind == rowIndex) {
                el.progressBarActive = false;
                el.progressBar = 50;
                el.isFileFieldEmpty = false;
            }
            return el;
        });

        //const updatedUserRows = userRows.map((row) => {
        //    if (row.id === rowId) {
        //        return { ...row, progressBarActive: false, progressBar: 50 };
        //    }
        //    return row;
        //});
        setUserRows(fileUsers);
        //setEmptyFile(false);

        const formConfig = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        };

        const file = event.target.files[0];
        setFileName(file.name);
        const formData = new FormData();
        formData.append("file", file);
        formData.append("fileName", file.name);
        let fileuploadurl = variables.API_URL + `DocumentFileUpload/uploadFile`;
        axios.post(fileuploadurl, formData, formConfig)
            .then((response) => {
                let fileName = response.data.fileName;

                let updatedUsers = userRows.map((el, index) => {
                    if (index == rowIndex) {
                        el.file = fileName;
                        el.progressBar = 100;
                        el.progressBarActive = true;
                    }
                    return el;
                });
                setUserRows(updatedUsers);
                SetBtnActive(true);
            })
            .catch(error => {
                alert('Error fetching data:', error);
                SetBtnActive(true);
                let fileUsers = userRows.map((el, ind) => {
                    if (ind == rowIndex) {
                        el.progressBarActive = true;
                        el.progressBar = 0;
                    }
                    return el;
                });
                setUserRows(fileUsers);
            });
    };

    const userNameDropDown = (e) => {
        let rowIndex = e.target.getAttribute("elementindex");

        let fileUsers = userRows.map((el, index) => {
            if (index == rowIndex) {
                el.userId = e.target.value;
                if (e.target.value != "") {
                    el.isUserIdDropdownEmpty = false;
                }
            }
            return el;
        });

        setUserRows(fileUsers);
        //setUserSelectDropDownName(e.target.value);
        //setEmptyDropDown(false);
    }

    const secondUserNameDropDown = (e) => {
        let rowIndex = e.target.getAttribute("elementindex");

        let fileUsers = userRows.map((el, index) => {
            if (index == +rowIndex) {
                el.dropdowns[0].userId = e.target.value;
                if (e.target.value != "") {
                    el.dropdowns[0].isUserIdDropdownEmpty = false;
                }
            }
            return el;
        });
        setUserRows(fileUsers);
    }

    const handleUserList = async () => {
        const url = "https://api.whise.eu/v1/admin/representatives/list";

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };

        const allwhiseOfficeList = whiseOfficesList;
        const allUserList = [];

        try {
            for (const item of allwhiseOfficeList) {
                const body = {
                    "officeId": `${item.id}`
                };

                const response = await axios.post(url, body, config);

                if (response.data && response.data.representatives) {
                    allUserList.push(response.data.representatives);
                } else {
                    allUserList.push([]);
                }
            }

            const flattenedUserList = allUserList.flat();

            const finalUserListAfterFilter = Object.values(
                flattenedUserList.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
            );

            setUserListDropDown(finalUserListAfterFilter);

            if (finalUserListAfterFilter.length > 0) {
                const transformedOptions = flattenedUserList.map(item => ({
                    label: `${item.firstName} ${item.name} ${item.id}`,
                    value: `${item.id}`
                }));

                setUserList(transformedOptions);
            } else {
                setUserList([]);
            }

        } catch (error) {
            console.error("Error fetching user list:", error.message);
        }
    };

    const getBaseDocumentTypeHandle = async () => {
        if (authUser == null) {
            return;
        }

        try {
            const response = await fetch(`https://api.whise.eu/reference?item=basedocumenttype`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${authUser.tokenValue}`,
                    'Content-Type': 'application/json'
                }
            });

            const jsonData = await response.json();
            if (jsonData != null) {
                const transformedOptions = jsonData.map(item => ({
                    label: `${item.name}`,
                    value: `${item.id}`,
                    pdfViewer: false
                }));
                setBaseDocumentTypesList(transformedOptions);
            } else {
                console.warn("Received null data");
            }
        } catch (error) {
            console.error("Failed to fetch trigger List:", error.message);
        }
    }

    const onChangeBaseDocumentList = (item) => {
        const baseDocumentTypeDropdow = document.querySelector(".documentBaseTypeMutliList");
        setSelectDocumentBaseTypeList(item);
        baseDocumentTypeDropdow.style.border = "none";
        baseDocumentTypeDropdow.style.borderRadius = "0px";
    }

    useEffect(() => {
        if (location.state != null && baseDocumentTypesList.length > 0) {
            getNavigateState();
        }

        //if (token == undefined) {
        //    return
        //}
        //const config = {
        //    headers: {
        //        'Authorization': `Bearer ${token}`,
        //        'Content-Type': 'application/json'
        //    },
        //};

        if (clientTokken == "" || clientTokken == undefined) {
            return
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${clientTokken}`,
                'Content-Type': 'application/json'
            },
        };

        if (userList.length == 0) {
            handleUserList();
        }

        if (baseDocumentTypesList.length == 0) {
            getBaseDocumentTypeHandle();
        }

        let enData = [], frData = [], nlData = [];
        axios.post('https://api.whise.eu/v1/calendars/actions/list', { "LanguageId": "en-GB" }, config) // ASP.NET Core API endpoint with headers
            .then(response => {
                //  setData(response.data.calendarActions);
                enData = response.data.calendarActions;

                axios.post('https://api.whise.eu/v1/calendars/actions/list', { "LanguageId": "fr-BE" }, config) // ASP.NET Core API endpoint with headers
                    .then(response => {
                        //setDatafr(response.data.calendarActions);
                        frData = response.data.calendarActions;

                        axios.post('https://api.whise.eu/v1/calendars/actions/list', { "LanguageId": "nl-BE" }, config) // ASP.NET Core API endpoint with headers
                            .then(response => {
                                //setDatanl(response.data.calendarActions);
                                nlData = response.data.calendarActions;

                                //merge data into one 
                                let mergedData = [];
                                let empty = " ";
                                for (let index = 0; index < enData.length + 1; index++) {
                                    if (enData[index] != undefined) {
                                        if (frData[index].name == undefined) {
                                            frData[index].name = empty;
                                        }
                                        if (enData[index].name == undefined) {
                                            enData[index].name = empty;
                                        }
                                        if (nlData[index].name == undefined) {
                                            nlData[index].name = empty;
                                        }
                                        //  data[index].name = data[index].name, datafr[index].name, datanl[index].name
                                        let newAction = enData[index];
                                        newAction.name = enData[index].name + ", " + frData[index].name + ", " + nlData[index].name
                                        mergedData.push(newAction);
                                    }
                                }
                                setData(mergedData);
                            })
                            .catch(error => {
                                console.error('Error fetching data:', error);
                            });

                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });

            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });


    }, [clientTokken, userList, baseDocumentTypesList])

    useEffect(() => {
        if (location.state != null) {
            getNavigateState();
        }

        if (token == undefined) {
            return
        }
        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
        };
        axios.post('https://api.whise.eu/v1/admin/clients/token', { "clientId": location.state.ClientDetail.id, "officeId": location.state.WhiseOffice.id }, config) // ASP.NET Core API endpoint with headers
            .then(response => {
                setClientTokken(response.data.token);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, [token])

    useEffect(() => {
        if (location.state.TriggerDetail != undefined && officeLayout.length != 0) {
            let filteredLayouts = officeLayout.filter(item => {
                return item.layoutid == location.state.TriggerDetail.layoutid
            })
            if (filteredLayouts.length > 0) {
                setSelectedLaytOutId(filteredLayouts[0].layoutid);
                document.getElementById("layoutDropdown").value = filteredLayouts[0].layoutid;
                setSelectedLaytName(filteredLayouts[0].layoutName);
            }
        }
    }, [officeLayout])

    useEffect(() => {
        if (location.state.TriggerDetail != undefined && texteTemplateData.length != 0) {
            let filteredTemplates = texteTemplateData.filter(item => {
                return item.templateId == location.state.TriggerDetail.texteTemplateId
            })
            if (filteredTemplates.length > 0) {
                setSelectedTexteId(filteredTemplates[0].templateId);
                document.getElementById("texteDropdown").value = filteredTemplates[0].templateId;
                setSelectedTexteName(filteredTemplates[0].templateName);
            }
        }
    }, [texteTemplateData])

    useEffect(() => {
        document.title = 'Trigger - Mindfree Trigger System';
    }, []);

    useEffect(() => {
        console.log(userRows);
    }, [userRows])

    useEffect(() => {
        if (recentlySavedLayout.layoutid != undefined) {
            updateSelectedLayoutStates(recentlySavedLayout);
        }
    }, [recentlySavedLayout]);

    useEffect(() => {
        if (recentlySavedTexteTemplate.templateId != undefined) {
            updateTexteTemplateState(recentlySavedTexteTemplate);
        }
    }, [recentlySavedTexteTemplate])

    useEffect(() => {
        if (location.state.TriggerDetail != undefined) {
            document.getElementById("whiseAppointmentType").value = location.state.TriggerDetail.appointmentType;
        }
    }, [data])

    return (
        <>
            <section className="client-setting">
                <div className="row py-3">
                    <div className="col-sm-12">
                        <h4 className="position-relative" >
                            <span className="position-absolute back-arrow" onClick={moveBackToOfficeScreen}>
                                <FontAwesomeIcon icon={faArrowLeft} />
                            </span>
                            <span id="addTrigger">Add Trigger</span>

                        </h4>
                    </div>
                    <div className="col-sm-12">
                        <label className="me-2 fw-bold">Client:</label><span>{clientDetail.name}</span><br />
                        <label className="me-2 fw-bold">Office:</label><span>{whiseOfficeDetail.name}</span>
                    </div>
                </div>
                <div className="card">
                    <div>
                        <h6 className="sub-heading fw-bold mb-3" id="tname">
                            {finalTriggerName}
                        </h6>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <label>Key Moment</label>
                            <select className="form-select" id="keymomentDropdown" onChange={setNameForTrigger}>
                                <option value="" key="">Select an option</option>
                                <option value="1" key="1">After creation of event</option>
                                <option value="2" key="2">Before the event date</option>
                                <option value="3" key="3">After the event date</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-4 mb-3">
                            <label>Trigger Type</label>
                            <select className="form-select" id="triggertypeDropdown" onChange={setNameForTrigger}>
                                <option value="">Select an option</option>
                                <option value="1">Email</option>
                                <option value="2">SMS</option>
                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <label>Type of Duration</label>
                            <select className="form-select" id="durationtypeDropdown" onChange={onChangeOfDurationType}>
                                <option value="">Select an option</option>
                                <option value="1">Days</option>
                                <option value="2">Hours</option>
                                <option value="3">Minutes</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-3">
                            <label>Value</label>
                            <input
                                type="number"
                                min="0"
                                onKeyDown={(e) => {
                                    if (e.key === '-')
                                        e.preventDefault()


                                }}
                                onChange={(e) => {
                                    let value = e.target.value;

                                    if (e.target.style.borderColor == "red") {
                                        e.target.style.borderColor = "#ced4da";
                                    }

                                    triggerNameBuilder[e.target.id] = "(" + value + ")";

                                    let nameString = ""
                                    Object.keys(triggerNameBuilder).map(item => {
                                        nameString += triggerNameBuilder[item] + " ";
                                    })

                                    setFinalTriggerName(nameString);
                                }}
                                className="form-control"
                                id="durationValue"
                                disabled
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-4 mb-3">
                            <label>Target</label>
                            <select className="form-select" id="participent1" onChange={setNameForTrigger}>
                                <option value="">Select an option</option>
                                <option value="1">Participant</option>
                                <option value="2">No Participant</option>
                            </select>
                        </div>

                    </div>
                    <div>
                        <h6 className="sub-heading fw-bold mb-3">Condition:</h6>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-2 mb-3">
                            <label>WHISE</label>
                            <select className="form-select" id="whiseAppointmentType" onChange={resetConditionDropdowns}>
                                <option value="">Select an option (en,fr,nl)</option>
                                {data?.map((option) => (
                                    <option key={option.id} value={option.id}>
                                        {option.name} ({option.id})
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="col-sm-12 col-md-3 mb-3">
                            <label>Property Transaction Type</label>
                            <MultiSelect
                                id="transactionType"
                                value={selectedProperty}
                                options={optionMultiSelect}
                                onChange={handleChangeSelected}
                                labelledBy="Select an option"
                            />
                        </div>
                        <div className="col-sm-12 col-md-3 mb-3">
                            <label>Property Transaction Status</label>
                            <select className="form-select" id="transactionStatus" onChange={resetConditionDropdowns}>
                                <option value="">Select an option</option>
                                <option value="1">To sale</option>
                                <option value="2">To rent</option>
                                <option value="3">Sold</option>
                                <option value="4">Rented</option>
                                <option value="5">Under option (sale)</option>
                                <option value="6">Under option rent</option>
                                <option value="7">Retiré de la vente</option>
                                <option value="8">Retiré de la Location</option>
                                <option value="9">Suspendu vendu</option>
                                <option value="10">Suspendu loué</option>
                                <option value="11">Option prop. Vendu</option>
                                <option value="12">Option prop. Loué</option>
                                <option value="13">Vendu avec cond. suspensive</option>
                                <option value="14">A vendre en viager</option>
                                <option value="15">Sous option en viager</option>
                                <option value="16">Vendu en viager</option>
                                <option value="17">Prospection</option>
                                <option value="18">Préparation vente</option>
                                <option value="19">Réservé</option>
                                <option value="20">Compromis</option>
                                <option value="21">Prospection location</option>
                                <option value="22">Estimation vente</option>
                                <option value="23">Estimation location</option>
                                <option value="24">Estimation rente viagère</option>
                                <option value="25">Préparation location</option>
                                <option value="26">Préparation vente en viager</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-2 mb-3">
                            <label>Include User List</label>
                            <MultiSelect
                                id="includeUser"
                                value={includeUserList}
                                options={userList}
                                onChange={handleIncludeUser}
                                labelledBy="Select an option"
                            />
                        </div>
                        <div className="col-sm-12 col-md-2 mb-3">
                            <label>Exclude User List</label>
                            <MultiSelect
                                id="excuseUser"
                                value={excuseUserList}
                                options={userList}
                                onChange={handleExcuseUser}
                                labelledBy="Select an option"
                            />
                        </div>
                    </div>
                    <div>
                        <h6 className="sub-heading fw-bold mb-3">Select Email Configuration:</h6>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mb-3">
                            <label className="me-3 mb-0 fw-bold">Survey Email:</label>
                        </div>
                    </div>

                    {/*Email Layout */}

                    <div className="row">
                        <div className="col-sm-12 col-md-5 mb-3">
                            <label className="me-3">Layout</label>
                            <div className="d-flex">
                                <select className="form-select" id="layoutDropdown" onChange={OnChangeHandlerEmailLayout}>
                                    <option value="">--Select Layout--</option>
                                    {
                                        officeLayout.length > 0 ? officeLayout.map((item, index) => {
                                            return (
                                                <option value={item.layoutid} key={index}>{item.layoutName}</option>
                                            )
                                        })
                                            :
                                            ""
                                    }
                                </select>
                                <button className="btn-site ms-1" onClick={openLayoutModal}>
                                    New
                                </button>
                                <button className="btn-site ms-1" onClick={openLayoutModal}>
                                    View
                                </button>
                                <button className="btn-site ms-1" onClick={openLayoutModal}>
                                    Edit
                                </button>
                                <button className="btn-delete ms-1" onClick={DeleteLayoutHandler}>
                                    Delete
                                </button>

                                <EmailLayoutModal
                                    showModal={showLayoutModal}
                                    modalTitle={layoutModalTitle}
                                    modalType={layoutModalType}
                                    officeId={officeId}
                                    clientId={clientId}
                                    hideLayoutModal={hideLayoutModal}
                                    layoutId={selectedLaytOutId}
                                    reloadLayoutsList={getListOfLayoutsByOffice}
                                    updateSelectedLayoutStates={updateSelectedLayoutStates}
                                />

                                <DeleteConfirmationModal
                                    showModelDelete={showModalDelete}
                                    deleteModalTitle={deleteModalTitle}
                                    deleteModalType={deleteModalType}
                                    hideModalDelete={hideModalDelete}
                                    itemId={selectedLaytOutId}
                                    itemName={selectedLaytName}
                                    dropdownItemsList={layoutsListWithoutCurrentLayout}
                                    client={clientDetail}
                                    office={localOfficeDetail}
                                    reloadSelectedItemDropdown={getListOfLayoutsByOffice}
                                    updateDropdownStatesAfterDelete={updateLayoutStatesAfterDelete}
                                    triggerDetail={triggerDetail}
                                />
                            </div>
                        </div>
                    </div>


                    {/* Texte Layout*/}

                    <div className="row">
                        <div className="col-sm-12 col-md-5 mb-3">
                            <label className="me-3">Texte Template</label>
                            <div className="d-flex">
                                <select className="form-select" id="texteDropdown" onChange={setSelectedEmailLayoutTexte}>
                                    <option value="">--Select Texte Template--</option>
                                    {
                                        texteTemplateData.length > 0 ? texteTemplateData.map((item, index) => {
                                            return (
                                                <option value={item.templateId} key={index} >{item.templateName}</option>
                                            )
                                        })
                                            :
                                            ""
                                    }
                                </select>
                                <button className="btn-site ms-1" onClick={openLayoutModalTexte}>
                                    New
                                </button>
                                <button className="btn-site ms-1" onClick={openLayoutModalTexte}>
                                    View
                                </button>
                                <button className="btn-site ms-1" onClick={openLayoutModalTexte}>
                                    Edit
                                </button>
                                <button className="btn-delete ms-1" onClick={deletetextehandler}>
                                    Delete
                                </button>
                                <EamilTexteModal
                                    showModalTexte={showTexteModal}
                                    texteModalTitle={texteModalTitle}
                                    modalType={texteModalType}
                                    officeId={officeId}
                                    clientId={clientId}
                                    hideTexteModal={hideTexteModal}
                                    texteTemplateId={selectedTexteId}
                                    reloadTexteDropdown={getListOfTexteTemplates}
                                    updateTexteStates={updateTexteTemplateState}
                                />

                                <DeleteConfirmationModal
                                    showModelDelete={showModalTexteDelete}
                                    deleteModalTitle={deleteModalTexteTitle}
                                    deleteModalType={deleteModalTexteType}
                                    hideModalDelete={hidetexteModalDelete}
                                    itemId={selectedTexteId}
                                    itemName={selectedTexteName}
                                    dropdownItemsList={texteListByWithoutCurrentTexte}
                                    client={clientDetail}
                                    office={localOfficeDetail}
                                    reloadSelectedItemDropdown={getListOfTexteTemplates}
                                    updateDropdownStatesAfterDelete={updateTexeteStatesAfterDelete}
                                    triggerDetail={triggerDetail}
                                />
                            </div>
                        </div>
                    </div>

                    {/*add new code abdul saboor */}

                    <div className="row">
                        <div className="col-sm-12 col-md-8">
                            <div className="d-flex align-items-center mb-3">
                                <FontAwesomeIcon icon={faPlus} />
                                <h6 className="ms-3 mb-0">Add document in the mail</h6>
                            </div>
                            <div className="d-flex justify-content-between mb-3">
                                <div>
                                    <input className="form-check-input" type="checkbox" id="customDocCheckBox" onChange={checkBoxActive} checked={checkActive} />
                                    <label className="form-check-label ms-3" htmlFor="customDocCheckBox">
                                        Custom Document (need of mapping)
                                    </label>
                                </div>
                                <div>
                                    <input className="form-check-input" type="checkbox" id="documentPropertyCheckBox" onChange={documentBaseTypeCheckBoxHandle} checked={documentPropertyCheckBox} />
                                    <label className="form-check-label ms-3" htmlFor="documentPropertyCheckBox">
                                        Documents of the property (select from the list)
                                    </label>
                                </div>
                            </div>
                            <div id="allDetailsUser-container" style={{ display: showAllDetailsUser ? "block" : "none" }}>
                                <h6 className="mb-3">Select if there must be a condition based on a specific object</h6>
                                <div>
                                    <label className="form-check-label me-3" htmlFor="userIdCheckBox">
                                        Condition on the User Id
                                    </label>
                                    <input className="form-check-input" type="checkbox" id="userIdCheckBox" onChange={checkBoxActive} checked={userCheckBoxActive} />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-4"></div>
                        <div id="mappingDoc-container" className="col-9" style={{ display: showMappingDoc ? "block" : "none" }}>
                            <h6 className="mb-3">Mapping of the documents</h6>
                            {
                                userRows.length > 0 ? userRows.map((row, rowIndex) => {
                                    return (
                                        <div id={`UserDetails-${row.id}`} className="mb-3 userMappingRule card" key={rowIndex}>
                                            <FontAwesomeIcon icon={faXmark} className="cross-icon fs-4 pe-auto ms-2" elementindex={rowIndex} onClick={removeUserRow} title="delete mapping" />
                                            <div className="d-flex align-items-end">
                                                <div id={`UserFeild-${row.id}`} className="" style={{ display: showUserField ? "block" : "none" }}>
                                                    <label>User Id
                                                        <span className="ms-2">
                                                            {row.showPlusIcon && <FontAwesomeIcon icon={faPlus} onClick={() => addNewUserDropDown(rowIndex)} />}
                                                        </span>
                                                    </label>
                                                    <select className={`form-select userIdOne ${row.isUserIdDropdownEmpty ? "border-danger" : ""}`} value={row.userId} elementindex={rowIndex} id={`userIdOne-${row.id}`} onChange={userNameDropDown}>
                                                        <option value="">Select the User</option>
                                                        {
                                                            userListDropDown.length > 0 ?
                                                                userListDropDown.map((item) => (
                                                                    <option value={item.id} key={item.id}>{`${item.firstName} ${item.name}`}</option>
                                                                )) : ""
                                                        }
                                                    </select>
                                                </div>
                                                <div className="dynamic-container align-items-end">
                                                    {
                                                        row.dropdowns != undefined ? row.dropdowns.map((dropdown) => {
                                                            return (
                                                                <div className="d-flex align-items-end" key={dropdown.id}>
                                                                    <div id={`andOrDropDown-${dropdown.id}`} elementindex={rowIndex} className="mx-2 and-or-dropDown block">
                                                                        <label htmlFor="" className="form-label"></label>
                                                                        <select className="form-select" value="1" aria-label="Default select example">
                                                                            <option value="1">AND</option>
                                                                        </select>
                                                                    </div>
                                                                    <div className="block">
                                                                        <label htmlFor={`userInputField-${dropdown.id}`} className="form-label">User Id
                                                                            <span className="ms-2">
                                                                                <FontAwesomeIcon icon={faXmark} onClick={() => removeUserdropdown(rowIndex, dropdown.id)} />
                                                                            </span>
                                                                        </label>
                                                                        <select className={`form-select userIdTwo ${dropdown.isUserIdDropdownEmpty ? "border-danger" : ""}`} value={dropdown.userId} id={`userIdTwo-${row.id}`} elementindex={rowIndex} onChange={secondUserNameDropDown}>
                                                                            <option value="">Select the User</option>
                                                                            {
                                                                                userListDropDown.length > 0 ?
                                                                                    userListDropDown.map((item) => (
                                                                                        <option value={item.id} key={item.id} >{`${item.firstName} ${item.name}`}</option>
                                                                                    ))
                                                                                    : ""
                                                                            }
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                            : <></>
                                                    }
                                                </div>
                                                <div id={`upload-close-icon-${row.id}`} className="ms-2 align-items-end" style={{ display: showUserField ? "flex" : "none" }}>
                                                    {
                                                        row.progressBarActive ?
                                                            <span>
                                                                <FontAwesomeIcon icon={faFileArrowUp} className={`fs-3 me-3 ${row.isFileFieldEmpty ? "text-danger" : ""}`} onClick={() => handleIconClick(rowIndex)} style={{ cursor: 'pointer' }} />
                                                                <input type="file" id={`fileInput-${row.id}`} elementindex={rowIndex} ref={el => fileInputRefs.current[rowIndex] = el} style={{ display: 'none' }} accept={".pdf"} onChange={handleFileChange} />
                                                                {row.file && <span>{row.file}</span>}
                                                            </span>
                                                            :
                                                            <div className="progress  w-100" id={`progress-${row.id}`} role="progressbar" aria-label="Basic example" aria-valuenow={progressBar} aria-valuemin="0" aria-valuemax="100">
                                                                <div className="progress-bar" style={{ width: `${row.progressBar}%` }}>{`${row.progressBar} %`}</div>
                                                            </div>
                                                    }
                                                </div>
                                            </div>

                                            <div id={`languages-container-${row.id}`} className="mt-3" style={{ display: showUserField ? "flex" : "none" }}>
                                                <div className="me-2">
                                                    <label>
                                                        English
                                                    </label>
                                                    <input type="text" id={`eng-inpField-${row.id}`} className={`form-control ${row.isEnEmpty ? "border-danger" : ""}`} elementindex={rowIndex} language="en" placeholder="Eng box" value={row.en} onChange={handleLanguageChange} />
                                                </div>
                                                <div className="me-2">
                                                    <label>
                                                        French
                                                    </label>
                                                    <input type="text" id={`fr-inpField-${row.id}`} className={`form-control ${row.isFrEmpty ? "border-danger" : ""}`} elementindex={rowIndex} language="fr" placeholder="French box" value={row.fr} onChange={handleLanguageChange} />
                                                </div>
                                                <div>
                                                    <label>
                                                        Dutch
                                                    </label>
                                                    <input type="text" id={`nl-inpField-${row.id}`} className={`form-control ${row.isNlEmpty ? "border-danger" : ""}`} elementindex={rowIndex} language="nl" placeholder="Dutch box" value={row.nl} onChange={handleLanguageChange} />
                                                </div>
                                                <div className="pt-3 mt-3 ps-2">
                                                    <input className="form-check-input mt-2" type="checkbox" id="pdfViewer" elementindex={rowIndex} onClick={pdfViewerCheckBoxActive} defaultChecked={row.pdfViewer != undefined ? row.pdfViewer : false} />
                                                    <label className="form-check-label ms-2 mt-1" htmlFor="pdfViewer">
                                                        Pdf Viewer
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                    :
                                    <></>
                            }
                            <p><FontAwesomeIcon className="fs-3 cursor-pointer" icon={faCirclePlus} onClick={addNewUserRow} title="add mapping" /></p>
                        </div>
                        <div className="col-3 mb-3" style={{ display: documentPropertyCheckBox ? "block" : "none" }}>
                            <div className="baseDocMultiSelect">
                                <div>
                                    <label>Document Base Type List</label>
                                    <MultiSelect
                                        id="documentBaseTypeMutliList"
                                        className="documentBaseTypeMutliList"
                                        value={selectDocumentBaseTypeList}
                                        options={baseDocumentTypesList}
                                        onChange={onChangeBaseDocumentList}
                                        labelledBy="Select an option"
                                    />
                                </div>
                                <div className="mt-3">
                                    <label>Enable Pdf Viewer</label>
                                    <div className="base-doc-pdf-viewer">
                                        {
                                            selectDocumentBaseTypeList.length > 0 ? selectDocumentBaseTypeList.map((item, i) => {
                                                return (
                                                    <div className="d-flex mt-1" key={i}>
                                                        <div>
                                                            <input className="form-check-input" type="checkbox" id="pdfViewer" elementindex={i} onClick={baseDocPdfViewerCheckBoxActive} defaultChecked={item.pdfViewer != undefined ? item.pdfViewer : false} />
                                                        </div>
                                                        <div className="ms-2">
                                                            <label className="form-check-label" htmlFor="pdfViewer">
                                                                {item.label}
                                                            </label>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                                :
                                                <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row d-none">
                        <div className="col-sm-12 col-md-12 mb-3">
                            <label className="me-3">Link</label>
                            <textarea className="form-control" rows="3" id="inputSurveyLink" onChange={replaceTriorFromLink}></textarea>
                        </div>
                    </div>
                    <div className="row d-none">
                        <div className="col-sm-12 mb-3">
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkAgent" parameter="agent" placeholder="{agent}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkAgent" >Agent</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkName" parameter="name" placeholder="{name}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkName" >Name</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkFirstname" parameter="Firstname" placeholder="{firstName}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkFirstname" >Firstname</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkLanguage" parameter="language" placeholder="{language}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkLanguage" >Language</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkZip" parameter="zip" placeholder="{zip}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkZip"  >Zip</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkEmail" parameter="email" placeholder="{email}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkEmail" >Email</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkCountry" parameter="country" placeholder="{country}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkCountry" >Country</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkOfficeid" parameter="officeID" placeholder="{officeid}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkOfficeid" >OfficeID</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkLoginofficeid" parameter="loginOfficeID" placeholder="{loginofficeid}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkLoginofficeid" >LoginOfficeID</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkContactid" parameter="contactID" placeholder="{contactid}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkContactid" >ContactID</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkLessor" parameter="profile" placeholder="lessor" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkLessor" >Profile=Lessor</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkBuyer" parameter="profile" placeholder="buyer" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkBuyer" >Profile=Buyer</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkTenant" parameter="profile" placeholder="tenant" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkTenant" >Profile=Tenant</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkVendor" parameter="profile" placeholder="vendor" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkVendor" >Profile=Vendor</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkEstimation" parameter="lessorestimation" placeholder="{lessorestimation}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkEstimation" >LessorEstimation</label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="checkbox" id="checkSatisfaction" parameter="npssatisfication" placeholder="{npssatisfication}" onChange={generateSurveyLink} />
                                <label className="form-check-label mb-0" htmlFor="checkSatisfaction" >NPSSatisfication</label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className="sub-heading fw-bold mb-3">Unsubscription Configuration:</h6>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mb-3">
                            <div className="form-check form-check-inline me-4">
                                <input class="form-check-input" type="checkbox" id="enableCustomUnsubscription" onChange={changeHandlerCustomUnsubscription} checked={isCustomUnsubscription} />
                                <label className="form-check-label" htmlFor="enableCustomUnsubscription">
                                    Enable Custom Unsubscription
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className="sub-heading fw-bold mb-3">Preferences:</h6>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-12 mb-3">
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="radio" name="preferenceRadio" id="checkAllEmail"
                                    preferencevalue="all" onChange={contactPreferenceChangeHandler} checked={contactPreference == "all"} />
                                <label className="form-check-label" htmlFor="checkAllEmail">
                                    Send mail to all contact's email
                                </label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="radio" name="preferenceRadio" id="checkPrivateEamil"
                                    preferencevalue="private" onChange={contactPreferenceChangeHandler} checked={contactPreference == "private"} />
                                <label className="form-check-label" htmlFor="checkPrivateEamil">
                                    Private email only
                                </label>
                            </div>
                            <div className="form-check form-check-inline me-4">
                                <input className="form-check-input" type="radio" name="preferenceRadio" id="checkBusinessEamil"
                                    preferencevalue="business" onChange={contactPreferenceChangeHandler} checked={contactPreference == "business"} />
                                <label className="form-check-label" htmlFor="checkBusinessEamil">
                                    Business email
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="text-center">
                        <button className="btn-site me-2" onClick={saveTrigger} disabled={!btnActive} >Save Trigger</button>{/*<button className="btn-site" onClick={createNewSurveyEmail}>Create New Version of Email</button>*/}
                    </div>
                </div>
            </section>
        </>
    )
}